/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// eslint-disable-next-line



import React, { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import CustomPagination from "./CustomPagination";

import Button from '@mui/material/Button'; 

import FilteredTable from "./FilteredTable";

// export function Publication({filter,data,setFilter,setSearchTerm,Link,bot,Pagination,totalData,searchTerm,tableRef,handleExcel,a,loading,companies,filterChanged})

// {  
//   const [itemCanton, setItemCanton] = useState(filter.canton);
//   const [itemLegal, setItemLegal] = useState([]);
//   const [itemNationality,setItemNationality]=useState([]);
//   const[itemSectors,setItemSectors]=useState([]);
//   const[itemServices,setItemServices]=useState([]);
//   const[itemsetSearchTerm,setItemSearchTerm]=useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };
//   useEffect(()=>{
//     console.log('loading is', loading)
//     console.log('Companies:', companies);
//   })
//   useEffect(() => {
//     console.log('filter length pub', filter.companyType
//     )
//       setItemCanton([...filter.canton])
//     },[filter])
//   useEffect(() => {
//     console.log('filter length pub', filter.companyType
//     )
//     setItemLegal([...filter.companyType
//     ])
//   },[filter])

//   useEffect(() => {
//     console.log('filter length pub', filter.nationality)
//       setItemNationality([...filter.nationality])
//   },[filter])

//   useEffect(() => {
//     console.log('filter length pub', filter.purposes)
//       setItemSectors([...filter.purposes])
//   },[filter])

//   useEffect(() => {
//     console.log('filter length pub', filter.services)
//       setItemServices([...filter.services])
//   },[filter])

//   useEffect(()=> {
//     console.log('search',searchTerm)
//     setItemSearchTerm(searchTerm)
//   },[searchTerm])


//   function UnSelectAllFilters (){
//     setItemCanton({...filter, canton :[]})
//     setItemLegal({...filter, companyType     :[]})
//     setItemNationality({...filter, nationality :[]})
//     setItemSectors({...filter, purposes :[]})
//     setItemServices({...filter, services :[]})
//     setFilter({...filter, canton: [],companyType :[],nationality :[],purposes :[],services :[]})
//   }
  

//     const handleDelete = (chipToDelete) => () => {
//       setItemCanton((chips) => chips.filter((chip) => chip !== chipToDelete));
//       setFilter({...filter, canton: filter.canton.filter(x=>x !== chipToDelete)})

//     };
//     const handleDeleteLegal = (chipToDelete) => () => {
//       setItemLegal((chips) => chips.filter((chip) => chip !== chipToDelete));
//       setFilter({...filter, companyType: filter.companyType.filter(x=>x !== chipToDelete)})

//     };
//     const handleDeleteNationality = (chipToDelete) => () => {
//       setItemNationality((chips) => chips.filter((chip) => chip !== chipToDelete));
//       setFilter({...filter, nationality: filter.nationality.filter(x=>x !== chipToDelete)})

//     };
//     const handleDeleteSectors = (chipToDelete) => () => {
//       setItemSectors((chips) => chips.filter((chip) => chip !== chipToDelete));
//       setFilter({...filter, purposes: filter.purposes.filter(x=>x !== chipToDelete)})

//     };
//     const handleDeleteServices = (chipToDelete) => () => {
//       setItemServices((chips) => chips.filter((chip) => chip !== chipToDelete));
//       setFilter({...filter, services: filter.services.filter(x=>x !== chipToDelete)})

//     };
//     const updateText = (event) => {
//       console.log('text',event.target.value)
//       setSearchTerm(event.target.value)
//     }

//      // Convert arrays to comma-separated strings for filter
//      const getFilterArray = (filterArray) => filterArray;

//      const convertedFilter = {
//        canton: itemCanton,
//        companyType: itemLegal,
//        nationality: itemNationality,
//        purposes: getFilterArray(itemSectors),
//        services: itemServices
//      };
     


//     return (
//       <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
//         <p className="resu">Filter Results</p>
//         <p>Data from 29.06.2022 - 16.09.2022</p>
//         <div className="paraqitja">
//           <div className="publication">
//             <p className="filter"><b>Publication:</b>{filter.publication}</p>
//           </div>
//           <div>
//             <p className="filter"><b>Canton:</b>
//               {itemCanton.map(data => (
//                 <Chip
//                   key={data}
//                   label={data}
//                   onDelete={handleDelete('canton', data)}
//                 />
//               ))}
//             </p>
//           </div>
//           <div>
//             <p className="filter"><b>Legal Form:</b>
//               {itemLegal.map(data => (
//                 <Chip
//                   key={data}
//                   label={data}
//                   onDelete={handleDelete('companyType', data)}
//                 />
//               ))}
//             </p>
//           </div>
//           <div>
//             <p className="filter"><b>Nationality:</b>
//               {itemNationality.map(data => (
//                 <Chip
//                   key={data}
//                   label={data}
//                   onDelete={handleDelete('nationality', data)}
//                 />
//               ))}
//             </p>
//           </div>
//           <div>
//             <p className="filter"><b>Sectors:</b>
//               {itemSectors.map(data => (
//                 <Chip
//                   key={data}
//                   label={data}
//                   onDelete={handleDelete('purposes', data)}
//                 />
//               ))}
//             </p>
//           </div>
//           <div>
//             <p className="filter"><b>Services:</b>
//               {itemServices.map(data => (
//                 <Chip
//                   key={data}
//                   label={data}
//                   onDelete={handleDelete('services', data)}
//                 />
//               ))}
//             </p>
//           </div>
//         </div>
//         <div className="ser">
//           <input
//             className="ch"
//             type="text"
//             placeholder="&#xF52A; Search Companies"
//             onChange={updateText}
//           />
//           <button onClick={handleExcel} className="exc">
//             <img src={a} alt="" />
//           </button>
//         </div>
//         <FilteredTable
//           companies={companies}
//           searchTerm={searchTerm}
//           convertedFilter={convertedFilter}
//           loading={loading}
//           tableRef={tableRef}
//         />
//         <div className="my-3">
//           <Pagination
//             activePage={currentPage}
//             itemsCountPerPage={10}
//             totalItemsCount={totalData}
//             pageRangeDisplayed={5}
//             onChange={handlePageChange}
//           />
//         </div>
//       </section>
//     );

  
//   }
            
// export default Publication;


export function Publication({
  currentPage,
  setCurrentPage,
  filter = {},       // Non-publication filters
  cleanedFilter = {},  // Publication filter
  setFilter,
  setSearchTerm,
  Link,
  bot,
  Pagination,
  totalData,
  searchTerm,
  tableRef,
  handleExcel,
  a,
  loading,
  companies,
  filterChanged
}) {
  // States for non-publication filters
  const [itemCanton, setItemCanton] = useState(filter.canton || []);
  const [itemLegal, setItemLegal] = useState(filter.companyType || []);
  const [itemNationality, setItemNationality] = useState(filter.nationality || []);
  const [itemSectors, setItemSectors] = useState(filter.purposes || []);
  const [itemServices, setItemServices] = useState(filter.services || []);
  const [itemSearchTerm, setItemSearchTerm] = useState(searchTerm || '');

  // Handle non-publication filters
  useEffect(() => {
    setItemCanton([...filter.canton || []]);
  }, [filter.canton]);

  useEffect(() => {
    setItemLegal([...filter.companyType || []]);
  }, [filter.companyType]);

  useEffect(() => {
    setItemNationality([...filter.nationality || []]);
  }, [filter.nationality]);

  useEffect(() => {
    setItemSectors([...filter.purposes || []]);
  }, [filter.purposes]);

  useEffect(() => {
    setItemServices([...filter.services || []]);
  }, [filter.services]);

  useEffect(() => {
    setItemSearchTerm(searchTerm || '');
  }, [searchTerm]);

  const UnSelectAllFilters = () => {
    setItemCanton([]);
    setItemLegal([]);
    setItemNationality([]);
    setItemSectors([]);
    setItemServices([]);
    setFilter({
      ...filter,
      canton: [],
      companyType: [],
      nationality: [],
      purposes: [],
      services: []
    });
  };

  const handleDelete = (type, chipToDelete) => () => {
    const updateFilter = (key, value) => setFilter({ ...filter, [key]: value });
    
    switch (type) {
      case 'canton':
        const updatedCantons = itemCanton.filter((chip) => chip !== chipToDelete);
        setItemCanton(updatedCantons);
        updateFilter('canton', updatedCantons);
        break;
      case 'companyType':
        const updatedLegal = itemLegal.filter((chip) => chip !== chipToDelete);
        setItemLegal(updatedLegal);
        updateFilter('companyType', updatedLegal);
        break;
      case 'nationality':
        const updatedNationality = itemNationality.filter((chip) => chip !== chipToDelete);
        setItemNationality(updatedNationality);
        updateFilter('nationality', updatedNationality);
        break;
      case 'purposes':
        const updatedSectors = itemSectors.filter((chip) => chip !== chipToDelete);
        setItemSectors(updatedSectors);
        updateFilter('purposes', updatedSectors);
        break;
      case 'services':
        const updatedServices = itemServices.filter((chip) => chip !== chipToDelete);
        setItemServices(updatedServices);
        updateFilter('services', updatedServices);
        break;
      default:
        break;
    }
  };

  const updateText = (event) => {
    const value = event.target.value || ''; 
    setItemSearchTerm(value);
    setFilter({ ...filter, city: value });
  };

  // Prepare filters to be applied
  const convertedFilter = {
    canton: itemCanton,
    companyType: itemLegal,
    nationality: itemNationality,
    purposes: itemSectors,
    services: itemServices,
  };

  // Ensure publication filtering uses the cleanedFilter
  const publicationFilter = cleanedFilter.publication || 'No Publication Selected';

  return (
    <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
      <p className="resu">Filter Results</p>
      <p>Data from 29.06.2022 - 16.09.2022</p>
      <div className="paraqitja">
        <div className="publication">
          <div className="filter"><b>Publication:</b>{publicationFilter}</div>
        </div>
        <div>
          <div className="filter"><b>Canton:</b>
            {itemCanton.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('canton', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Legal Form:</b>
            {itemLegal.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('companyType', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Nationality:</b>
            {itemNationality.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('nationality', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Sectors:</b>
            {itemSectors.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('purposes', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Services:</b>
            {itemServices.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('services', data)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="ser">
        <input
          className="ch"
          type="text"
          placeholder="&#xF52A; Search Companies"
          value={itemSearchTerm}
          onChange={updateText}
        />
        <button onClick={handleExcel} className="exc">
          <img src={a} alt="Excel Export" />
        </button>
      </div>
      {/* <FilteredTable
        companies={companies}
        searchTerm={itemSearchTerm}
        convertedFilter={convertedFilter}
        loading={loading}
        tableRef={tableRef}
      /> */}
      <FilteredTable
        companies={companies}
        searchTerm={itemSearchTerm}
        convertedFilter={convertedFilter}
        publicationFilter={publicationFilter}
        loading={loading}
        tableRef={tableRef}
      />

      {/* <FilteredTable
        companies={companies}
        searchTerm={itemSearchTerm}
        convertedFilter={convertedFilter}
        publicationFilter={cleanedFilter.publication}
        loading={loading}
        tableRef={tableRef}
      /> */}

      <div className="my-3">
        <CustomPagination
          totalData={totalData}
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={setCurrentPage}
        />
      </div>
    </section>
  );
}

export default Publication;
// export function Publication({currentPage, setCurrentPage,filter,data,setFilter,setSearchTerm,Link,bot,Pagination,totalData,searchTerm,tableRef,handleExcel,a,loading,companies,filterChanged})
//  {
//   const [itemCanton, setItemCanton] = useState(filter.canton || []);
//   const [itemLegal, setItemLegal] = useState(filter.companyType || []);
//   const [itemNationality, setItemNationality] = useState(filter.nationality || []);
//   const [itemSectors, setItemSectors] = useState(filter.purposes || []);
//   const [itemServices, setItemServices] = useState(filter.services || []);
//   const [itemSearchTerm, setItemSearchTerm] = useState(searchTerm || '');
//   // const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     setItemCanton([...filter.canton]);
//   }, [filter.canton]);

//   useEffect(() => {
//     setItemLegal([...filter.companyType]);
//   }, [filter.companyType]);

//   useEffect(() => {
//     setItemNationality([...filter.nationality]);
//   }, [filter.nationality]);

//   useEffect(() => {
//     setItemSectors([...filter.purposes]);
//   }, [filter.purposes]);

//   useEffect(() => {
//     setItemServices([...filter.services]);
//   }, [filter.services]);

//   useEffect(() => {
//     setItemSearchTerm(searchTerm);
//   }, [searchTerm]);

//   const UnSelectAllFilters = () => {
//     setItemCanton([]);
//     setItemLegal([]);
//     setItemNationality([]);
//     setItemSectors([]);
//     setItemServices([]);
//     setFilter({
//       ...filter,
//       canton: [],
//       companyType: [],
//       nationality: [],
//       purposes: [],
//       services: []
//     });
//   };

//   const handleDelete = (type, chipToDelete) => () => {
//     const updateFilter = (key, value) => setFilter({ ...filter, [key]: value });
    
//     switch (type) {
//       case 'canton':
//         const updatedCantons = itemCanton.filter((chip) => chip !== chipToDelete);
//         setItemCanton(updatedCantons);
//         updateFilter('canton', updatedCantons);
//         break;
//       case 'companyType':
//         const updatedLegal = itemLegal.filter((chip) => chip !== chipToDelete);
//         setItemLegal(updatedLegal);
//         updateFilter('companyType', updatedLegal);
//         break;
//       case 'nationality':
//         const updatedNationality = itemNationality.filter((chip) => chip !== chipToDelete);
//         setItemNationality(updatedNationality);
//         updateFilter('nationality', updatedNationality);
//         break;
//       case 'purposes':
//         const updatedSectors = itemSectors.filter((chip) => chip !== chipToDelete);
//         setItemSectors(updatedSectors);
//         updateFilter('purposes', updatedSectors);
//         break;
//       case 'services':
//         const updatedServices = itemServices.filter((chip) => chip !== chipToDelete);
//         setItemServices(updatedServices);
//         updateFilter('services', updatedServices);
//         break;
//       default:
//         break;
//     }
//   };

//   // const updateText = (event) => {
//   //   const value = event.target.value;
//   //   setSearchTerm(value);
//   // };

//   const updateText = (event) => {
//     const value = event.target.value || ''; 
//     setItemSearchTerm(value);
//     setFilter({ ...filter, city: value });
//   };
  
  


//   const convertedFilter = {
//     canton: itemCanton,
//     companyType: itemLegal,
//     nationality: itemNationality,
//     purposes: itemSectors,
//     services: itemServices,
//     publications: filter.publications || []
//   };

//   return (
//     <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
//       <p className="resu">Filter Results</p>
//       <p>Data from 29.06.2022 - 16.09.2022</p>
//       <div className="paraqitja">
//         <div className="publication">
//           <div className="filter"><b>Publication:</b>{filter.publication}</div>
//         </div>
//         <div>
//           <div className="filter"><b>Canton:</b>
//             {itemCanton.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('canton', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Legal Form:</b>
//             {itemLegal.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('companyType', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Nationality:</b>
//             {itemNationality.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('nationality', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Sectors:</b>
//             {itemSectors.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('purposes', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Services:</b>
//             {itemServices.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('services', data)}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="ser">
//         <input
//           className="ch"
//           type="text"
//           placeholder="&#xF52A; Search Companies"
//           value={itemSearchTerm}
//           onChange={updateText}
//         />
//         <button onClick={handleExcel} className="exc">
//           <img src={a} alt="Excel Export" />
//         </button>
//       </div>
//       <FilteredTable
//         companies={companies}
//         searchTerm={itemSearchTerm}
//         convertedFilter={convertedFilter}
//         loading={loading}
//         tableRef={tableRef}
//       />
//       <div className="my-3">
//         {/* <Pagination
//           activePage={currentPage}
//           itemsCountPerPage={10}
//           totalItemsCount={totalData}
//           pageRangeDisplayed={5}
//           onChange={setCurrentPage}
//         /> */}
//         <CustomPagination
//           totalData={totalData}
//           currentPage={currentPage}
//           itemsPerPage={10}
//           onPageChange={setCurrentPage}
//         />
//       </div>
//     </section>
//   );
// }

// export default Publication;
