import React from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import Details from './components/Details/details';
import Home from './components/Charts/login';
import BarChart from './components/Charts/Barchart';
import Login from './components/Login/Login';
import Test from './components/Home/home';
import Registration from './components/Register/registration';
import Pricing from './components/Pricing/pricing';
import CompanyAdvancedSearch from './components/Home/CompanyAdvancedSearch';



function App() {
  return (
    <Router>
      <Routes>
        <Route exact element={<PrivateRoute />}>
          <Route exact path="/Dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Home />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path ="/" element ={<Login />} />
        <Route path="/Barchart" element={<BarChart />} />
        <Route path="/home" element={<Test />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/company-advanced-search" element={<CompanyAdvancedSearch />} />
      </Routes>
    </Router>
  );
}
export default App;
