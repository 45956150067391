/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './details.css';
import Navbar from '../Navbar/Navbar';
import 'jspdf-autotable';
import { Graph } from "react-d3-graph";
import {CustomNode} from 'react';

import { COMPANY_API, SECTOR_API, COMPANY_TYPE_API } from '../../api/api';
function Details() {
  
  const [details, setDetails] = useState({});
  const [persons, setPersons] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [dateOfActs, setDateOfActs] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [publications, setPublications] = useState([]);
  const [additionalPayments, setAdditionalPayments] = useState([]);
  const [company, setCompany] = useState({});
  const [sectors, setSectors] = useState([]);



  const [purpose, setPurpose] = useState("");

  const [currentCompany, setCurrentCompany] = useState({});
  
  const { id } = useParams();

  // const getDetails = async () => {
  //   const token = localStorage.getItem('TOKEN');
  //   try {
  //     const response = await axios.get(COMPANY_API.getCompanyById(id), {
  //       headers: {
  //         Authorization: `Bearer ${token}`, // Ensure Bearer token is enclosed in backticks
  //       },
  //     });
  //     console.log('Response:', response.data);
  //     setDetails(response.data.data || {}); // Adjusted to response.data.data
  //     setPersons(response.data.data.persons || []); // Ensure persons is an array
  //     setCurrentCompany(response.data.data || {}); // Adjusted to response.data.data
  //   } catch (error) {
  //     console.error('Failed to fetch company details:', error);
  //   }
  // };
  
  // useEffect(() => {
  //   getDetails();
  // }, [id]);

  const getDetails = async () => {
    const token = localStorage.getItem('TOKEN');
    try {
      const response = await axios.get(COMPANY_API.getCompanyById(id), {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data.data || {};
      setDetails(data);
      setPersons(data.persons || []);
      setCompany(data);
    } catch (error) {
      console.error('Failed to fetch company details:', error);
    }
  };

  const getSectors = async () => {
    try {
      const response = await axios.get(SECTOR_API.getAllSectors);
      console.log('Fetched Sectors:', response.data); // Debugging
      setSectors(response.data || []);
    } catch (error) {
      console.error('Failed to fetch sectors:', error);
    }
  };

  const inferCompanyPurpose = () => {
    console.log("Details:", details);
    console.log("Sectors:", sectors);
  
    if (!details || !details.sectorId || !sectors.length) {
      setPurpose("Purpose information is not available.");
      return;
    }
  
    const sectorId = String(details.sectorId); // Convert to string if necessary
    const sector = sectors.find(sector => String(sector.id) === sectorId);
  
    console.log("Sector ID being searched for:", sectorId);
    console.log("Sector found:", sector);
  
    if (sector) {
      setPurpose(`Sector: ${sector.name}`);
    } else {
      setPurpose("Purpose information is not available.");
    }
  };
  
  

  useEffect(() => {
    getDetails();
    getSectors();
  }, [id]);

  useEffect(() => {
    // if (details?.sectorId && sectors.length) {
      inferCompanyPurpose(details);
    // }
  }, [sectors, details]);


  const data = {
    nodes: [
      {
        id: company?.companyName || 'Company Name',
        svg: details?.logo || "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
        fontSize: 16
      },
      ...(persons || [])
        .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
        .map((item, index) => ({
          id: `${item?.personalData || 'Unknown'}-${index}`,
          svg: "https://cdn-icons-png.flaticon.com/512/3033/3033143.png",
          size: 400,
          fontSize: 12
        })),
      ...(persons || [])
        .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
        .map((item, index) => ({
          id: `${item?.personalData || 'Unknown'}-${index}-filtered`,
          svg: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
          size: 400,
          fontSize: 12
        }))
    ],
    links: [
      ...(persons || [])
        .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
        .map((item, index) => ({
          source: company?.companyName || 'Company Name',
          target: `${item?.personalData || 'Unknown'}-${index}`
        })),
      ...(persons || [])
        .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
        .map((item, index) => ({
          source: company?.companyName || 'Company Name',
          target: `${item?.personalData || 'Unknown'}-${index}-filtered`
        }))
    ]
  };
  

  
  // const listItems = (
  //   <div>
  //     {(details?.persons || []).map((item) => (
  //       <div key={item.uniqueId}>
  //         <p className={!item.activeDataPerson ? 'nodat' : ''}>
  //           {item.personalData}
  //         </p>
  //       </div>
  //     ))}
  //   </div>
  // );
  
  const listItems = (
    <div>
      {(details?.persons || []).map((item) => (
        <div key={item.uniqueId}>
          <p className={!item?.activeDataPerson ? 'nodat' : ''}>
            {item?.personalData}
          </p>
        </div>
      ))}
    </div>
  );
  
  // const datas = (
  //   <div>
  //     {Object.keys(persons).map(key => ( 
  //       <p>{persons[key].personalData}</p>
  //     ))}
  //   </div>
  // );

  const datas = (
    <div>
      {Object.keys(persons || {}).map(key => ( 
        <p key={key}>{persons[key].personalData}</p>
      ))}
    </div>
  );
  
  
  console.log({ datas });

  // const data = {
  //   nodes: [
  //     {
  //       id: currentCompany?.companyName || 'Company Name',
  //       svg: details?.logo || "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
  //       fontSize: 16
  //     },
  //     ...(details?.persons || [])
  //       .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
  //       .map((item, index) => ({
  //         id: `${item?.personalData || 'Unknown'}-${index}`,  
  //         svg: "https://cdn-icons-png.flaticon.com/512/3033/3033143.png",
  //         size: 400,
  //         fontSize: 12
  //       })),
  //     ...(details?.persons || [])
  //       .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
  //       .map((item, index) => ({
  //         id: `${item?.personalData || 'Unknown'}-${index}-filtered`,
  //         svg: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png", 
  //         size: 400,
  //         fontSize: 12
  //       }))
  //   ],
  //   links: [
  //     ...(details?.persons || [])
  //       .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
  //       .map((item, index) => ({
  //         source: currentCompany?.companyName || 'Company Name',
  //         target: `${item?.personalData || 'Unknown'}-${index}`
  //       })),
  //     ...(details?.persons || [])
  //       .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
  //       .map((item, index) => ({
  //         source: currentCompany?.companyName || 'Company Name',
  //         target: `${item?.personalData || 'Unknown'}-${index}-filtered`
  //       }))
  //   ]
  // };

  const myConfig = {
    nodeHighlightBehavior: true,
    maxZoom:1,   
    panAndZoom: false,
    staticGraph: false,
    directed: false,
    automaticRearrangeAfterDropNode: false,
    d3: {
      alphaTarget: 0.05,
      gravity: -250,
      linkLength: 200,
      linkStrength: 2

    },
    node: {
      color: "lightgreen",
      size: 700,
      highlightStrokeColor: "blue",
      mouseCursor: "pointer",
      opacity: 1,
      strokeColor: "none",
      strokeWidth: 1.5,
      labelProperty: 'id',
      highlightFontSize:14,
      labelPosition :'bottom',
      svg: "",
      symbolType: "circle",
      // viewGenerator: (node) => <CustomNode token={node} />
    },
    link: {
      color: "#d3d3d3",
      opacity: 1,
      semanticStrokeWidth: false,
      strokeWidth: 4,
      highlightColor: "red"
    }
  };
  

  
  return (
    <div className="">
      <div className="no-printme">
        <Navbar />
      </div>
      <div className="container">
        <div className="water">
          <div className="row all printme" id="pdf">
            <div className="col-lg-12">
              <div className="card text-center"></div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <i className="bi bi-clipboard deti de"></i>
                        &nbsp;&nbsp;{company?.companyName}
                      </h5>
                    </div>
                    <div className="card-body uid">
                      <p className="card-text">
                        <p><b>Unique ID : </b>{company?.che}</p>
                      </p>
                      <p className="card-text" style={{ marginRight: '30px' }}>
                        <p><b>Date of registration :</b> {company?.dateOfRegistration}</p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 id">
                  <div className="card">
                    <div
                      className="card-body but"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="card-text" style={{ marginRight: '30px' }}>
                        <button
                          className="pdf no-printme"
                          onClick={() => window.print()}
                        >
                          <i class="bi bi-file-earmark-pdf pdfff"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">In</th>
                            <th scope="col">
                              <i
                                className="bi bi-building deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;Bussines name
                            </th>
                            <th scope="col">Legal Form</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr  
                            style={{ borderBottom: '1px solid #dee2e6' }}
                            >
                            <th scope="row">{company.activeData}</th>
                            <td>
                              <p className={!currentCompany.activeData ? 'stike' : ''}>{company.companyName}</p>
                            </td>
                            <td>{company.companyType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                
                <div className="col-sm-3" style={{ marginTop: '10px' }}>
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">Ref</th>
                          <th scope="col">
                            <i
                              className="bi bi-house deti "
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Legal Seat
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(details?.cities || []).length > 0 ? (
                          (details?.cities || []).map((item, index) => (
                            <tr
                              style={{ borderBottom: '1px solid #dee2e6' }}
                              key={item.uniqueId}
                            >
                              <th scope="row">{index + 1}</th>
                              <td>
                                <p className={!item.activeDataCity ? 'stike' : ''}>
                                  {item.city}
                                </p>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No Legal Seats Available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-6" style={{ marginTop: '20px' }}>
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">Ref</th>
                            <th scope="col">
                              <i
                                className="bi bi-geo deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;Address
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(details?.addresses || []).length > 0 ? (
                            (details?.addresses || []).map((item, index) => (
                              <tr
                                style={{ borderBottom: '1px solid #dee2e6' }}
                                key={`${item.uniqueId}-${index}`}
                              >
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <p className={!item.activeDataAddress ? 'stike' : ''}>
                                    {item.address}
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No Addresses Available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6" style={{ marginTop: '20px' }}>
  <div className="card">
    <div className="card-body">
      <table className="table detaj">
        <thead>
          <tr>
            <th scope="col">In</th>
            <th scope="col">
              <i className="bi bi-journal-check deti"></i>&nbsp;Date of Acts
            </th>
          </tr>
        </thead>
        <tbody>
          {(details?.dateOfActs || []).length > 0 ? (
            (details.dateOfActs || []).map((item, index) => (
              <tr style={{ borderBottom: '1px solid #dee2e6' }} key={`${item.uniqueId}-${index}`}>
                <th scope="row">{index + 1}</th>
                <td className={!item.activeDataOfActs ? 'stike' : ''}>
                  {item.dateOfActs}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No Date of Acts Available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

              </div>



              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">In</th>
                            <th scope="col">Purpose</th>
                          </tr>
                        </thead>
                        <tbody>
                        {(details?.purpose || []).map((item,index) => ( <tr  
                              style={{ borderBottom: '1px solid #dee2e6' }}
                              key={index}
                              >
                              <th scope="row">{index + 1}</th>
                              <td>
                                <p className={!item.activeDataPurpose ? 'stike' : ''}>{item.Purpose}</p>
                              </td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">In</th>
                            <th scope="col">
                              <i
                                className="bi bi-bookmark deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;Remarks
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {(details?.remarks || []).map((item,index) => ( <tr  
                              style={{ borderBottom: '1px solid #dee2e6' }}
                              key={`${item.uniqueId}-${index}`}
                              >
                              <th scope="row">{index + 1}</th>
                              <td>
                                <p className={!item.activeDataRemark ? 'stike' : ''}>{item.remark}</p>
                              </td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table last detaj">
                        <thead>
                          <tr>
                            <th scope="col">Ref</th>
                            <th scope="col">Journal</th>
                            <th scope="col">Date</th>
                            <th scope="col">SOGC</th>
                            <th scope="col">Date SOGC</th>
                            <th scope="col">Page ID</th>
                          </tr>
                        </thead>
                        <tbody>
                        {(details?.publications || []).map((item,index) => ( <tr  
                              style={{ borderBottom: '1px solid #dee2e6' }}
                              key={`${item.uniqueId}-${index}`}
                              >
                              <th scope="row">{index + 1}</th>
                            <td>{item.journalNumber}</td>
                            <td>{item.journalDate}</td>
                            <td>{item.SOGCNumber}</td>
                            <td>{item.SOGCDate}</td>
                            <td>
                              <a href="#" className='pageid'>{item.Page}</a>
                            </td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12" style={{ marginTop: '20px' }}>
                <div className="card">
                <div className="card-body">
                  <table className="table detaj">
                    <thead>
                      <tr>
                        <th scope="col">In</th>
                        <th scope="col">Additional Payments</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(details?.additonalPayments || []).map((item,index) => ( <tr 
                         style={{ borderBottom: '1px solid #dee2e6' }}
                         key={`${item.uniqueId}-${index}`}
                         >
                          <th scope="row">{index + 1}</th>
                          <td className={!item.activeDataPayment ? 'stike' : ''}>
                            {item.additonalPayment}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tbody></tbody>
                  </table>
                </div>
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">In</th>
                          <th scope="col">Qualited Facts</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">In</th>
                          <th scope="col">
                            <i
                              className="bi bi-people deti"
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Personal Data
                          </th>
                          <th scope="col">Function</th>
                          <th scope="col">
                            <i
                              className="bi bi-pencil deti"
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Signature
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {(details?.persons || []).map((item,index) => ( <tr 
                         style={{ borderBottom: '1px solid #dee2e6' }}
                         key={`${item.uniqueId}-${index}`}
                         >
                          <th scope="row">{index + 1}</th>
                          <td className={!item.activeDataPerson ? 'stike' : ''}>
                            {item.personalData}
                          </td>
                          <td>{item.function}</td>
                          <td>{item.signature}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                    <div>
                    { <Graph
      id="graph-id"
      data={data}
      config={myConfig}
      
    /> }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
