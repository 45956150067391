/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import Navbar from "../Navbar2/navbar2";
import "./dashboard.css";
import CountUp from "react-countup";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import * as bot from "react-bootstrap";
import { FilterList } from "./FilterList";
import Pagination from "react-js-pagination";
import ApexChart from "./Chart";
import Bar from "./Bar";
import a from "../../images/excel (1).png";
import Publication from "./Publication";

import NationalityFilterDashboard from "./NationalitiFilterDashboard";

const PublicationTypes = {
  TodayPublication: "Today Publications",
  AllAlbanian: "All Albanian Publications",
  AllPublications: "All Publications",
};

function Dashboard() {
  const [filter, setFilter] = useState({

    canton: [],
    companyType: [],
    nationality: [],
    purposes: [],
    services: [],
    city: "",
    date: "", 
    publications: []
  });

  const [filterLocked, setFilterLocked] = useState(false);



  const [countAll, setCountAll] = useState(null);
  const [countAl, setCountAl] = useState(null);
  const [countT, setCountT] = useState(null);
  const [countTAL, setCountTAL] = useState(null);


  const [cleanedFilter, setCleanedFilter] = useState({});

  // Function to update cleanedFilter based on the current filter state
  const updateCleanedFilter = () => {
    const newCleanedFilter = {};

    // Only include non-empty filter criteria
    Object.keys(filter).forEach((key) => {
      if (filter[key] && (Array.isArray(filter[key]) ? filter[key].length > 0 : filter[key])) {
        newCleanedFilter[key] = filter[key];
      }
    });

    setCleanedFilter(newCleanedFilter);
  };

  // Use useEffect to update cleanedFilter whenever filter changes
  useEffect(() => {
    updateCleanedFilter();
  }, [filter]);

  const publicationList = useMemo(
    () =>
      Object.keys(PublicationTypes).map((key) => ({
        name: PublicationTypes[key],
        id: key,
      })),
    []
  );

  function UnSelectAll() {
    var items = document.getElementsByClassName("checkbox");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") items[i].checked = false;
    }
  }

  const [totalData, setTotalData] = useState(0);
  const [companies, setCompanies] = useState([]);

  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);
  const handleExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(companies);

    XLSX.utils.book_append_sheet(wb, ws, "Companies");

    XLSX.writeFile(wb, "Companies.xlsx");
  };
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);

  // const onCheckBoxClicked = (key, value) => {
  //   console.log("canton list", filter[key]);
  //   setFilter({
  //     ...filter,
  //     [key]: value,
  //   });
  //   setSelectedCheckBox({ ...selectedCheckBox, [key]: value });
  //   console.log("canton list after update:", value);

  // };
  const onCheckBoxClicked = (key, value) => {
    setFilterLocked(true);
    setFilter((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
    // setSelectedCheckBox({ ...selectedCheckBox, [key]: value });
  };
  

  



  //test code
  // const convertSectorFilter = (filter) => {
  //   const newFilter = { ...filter };
  
  //   if (Array.isArray(newFilter.purposes) && newFilter.purposes.length) {
  //     newFilter.purposes = newFilter.purposes.join(',');
  //   } else {
  //     delete newFilter.purposes;
  //   }
  
  //   console.log("Converted Filter:", newFilter); // Debugging line
  //   return newFilter;
  // };

  const convertSectorFilter = (filter) => {
    const newFilter = { ...filter };
    if (Array.isArray(newFilter.purposes) && newFilter.purposes.length) {
      newFilter.purposes = newFilter.purposes.join(',');
    } else {
      delete newFilter.purposes;
    }


    if (Array.isArray(newFilter.nationality) && newFilter.nationality.length) {
      // Keep it as an array
      newFilter.nationality = newFilter.nationality;
    } else {
      delete newFilter.nationality;
    }
    

    if (Array.isArray(newFilter.services) && newFilter.services.length) {
      newFilter.services = newFilter.services;
    } else {
      delete newFilter.services;
    }

    console.log("Converted Filter:", newFilter); // Debugging line
    return newFilter;
  };
  
  
  
  
  

  useEffect(() => {
    console.log("search companies", searchTerm);
  }, [searchTerm]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getCompanies(currentPage);
  }, [filter, currentPage]);

  
  const getCompanies = async (page) => {
    console.log("Fetching companies for page:", page);
    console.log("Filter Object:", filter);
    try {

      console.log("Filter", filter);

      const testPublication = "All Albanian Publications";

      const token = localStorage.getItem("TOKEN");
      let endpoint = `https://api.kseco.eu/api/companies?page=${page}`;

      // const params = convertFilterToParams({ ...filter });

      // if (filter.publications === "All Albanian Publications") {
      //   endpoint = `https://api.kseco.eu/api/companies?publication=albanian&page=${page}`;
      // } else if (filter.publications === "Today Publications") {
      //   endpoint = `https://api.kseco.eu/api/companies?publication=today&page=${page}`;
      // }

      

      const sectorConvertedFilter = convertSectorFilter(filter);

      const cleanedFilter = Object.fromEntries(
        Object.entries(sectorConvertedFilter).filter(([_, v]) => v != null)
      );

      if (cleanedFilter.publication && cleanedFilter.publication.length > 0) {
        if (cleanedFilter.publication.includes("All Albanian Publications")) {
          endpoint = `https://api.kseco.eu/api/companies?publication=albanian&page=${page}`;
        } else if (cleanedFilter.publication.includes("Today Publications")) {
          endpoint = `https://api.kseco.eu/api/companies?publication=today&page=${page}`;
        } else {
          endpoint = `https://api.kseco.eu/api/companies?page=${page}`;
        }
      }
      if (filter.publications) {
        cleanedFilter.publication = filter.publications;
      }else {
        delete cleanedFilter.publication;
      }

      if (filter.canton.length) {
        cleanedFilter.canton = filter.canton;
    } else {
        delete cleanedFilter.canton;
    }
    if (filter.city) {
        cleanedFilter.city = filter.city;
    } else {
        delete cleanedFilter.city; // Ensure the city filter is removed if it's not set
    }

    if (filter.date) {
      cleanedFilter.date = filter.date;
    } else {
      delete cleanedFilter.date;
    }
      

    if (filter.services.length) {
      cleanedFilter.services = filter.services;
    } else {
      delete cleanedFilter.services; // Ensure it's removed if no services are selected
    }

    
      console.log("Cleaned Filter Parameters:", cleanedFilter);

      // Make the API request
      // const response = await axios.get(endpoint, {
      //   params: cleanedFilter,
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });



      //correct code
      const response = await axios.get(endpoint, {
        params: { ...cleanedFilter, page: page },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // const response = await axios.get(endpoint, {
      //   params: { ...filter },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      console.log("COMPLETECODE" ,response.data)

      const total = response.data.meta.total;

      setCompanies(response.data.data);
      console.log("totoaldata",total)
      setTotalData(total); 
    } catch (error) {
      console.log(error);
      setCompanies([]);
    }
  };

  useEffect(() => {
    if (filter.publication) {
      getCompanies(currentPage);
    }
  }, [filter, currentPage]);


  


  const handlePageChange = (pageNumber) => {
    console.log("Page number clicked:", pageNumber);

    setCurrentPage(pageNumber);
  };

  const [purposes, setPurposes] = useState([]);

  const getPurposes = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/sectors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Nationality response:", response.data); // Log response data
      setPurposes(response.data.data); // Update nationality state with response data
    } catch (error) {
      console.error("Error fetching nationality data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  useEffect(() => {
    getPurposes();
  }, []);

  const [canton, setCanton] = useState([]);

  const getCantons = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/cantons", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Nationality response:", response.data); // Log response data
      setCanton(response.data.data); // Update nationality state with response data
    } catch (error) {
      console.error("Error fetching nationality data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };
  
  useEffect(() => {
    getCantons();
  }, []);

  const [companyType, setCompanyType] = useState([]);


  const getCompanyType = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/companytypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Nationality response:", response.data); // Log response data
      setCompanyType(response.data.data); // Update nationality state with response data
    } catch (error) {
      console.error("Error fetching nationality data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  useEffect(() => {
    getCompanyType();
  }, []);

  const [nationality, setNationality] = useState([]);



  const getNationality = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/nationalities", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Nationality response:", response.data); // Log response data
      setNationality(response.data.data); // Update nationality state with response data
    } catch (error) {
      console.error("Error fetching nationality data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };
  
  useEffect(() => {
    getNationality();
  }, []);

  const [service, setService] = useState([]);

  const getService = async () => {
    const token = localStorage.getItem("TOKEN");
    console.log("Getting the services");
    const response = await axios.get(
      "https://api.kseco.ch/api/services",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setService(response.data);
    console.log("Services", response.data)
  };

  useEffect(() => {
    getService();
  }, []);


  const fetchCounts = async () => {
    try {
      // Fetch total companies
      const responseAll = await axios.get("https://api.kseco.eu/api/companies-statistics");
      setCountAll(responseAll.data.data.total_companies);
  
      // Fetch Albanian companies
      const responseAl = await axios.get("https://api.kseco.eu/api/companies-statistics");
      setCountAl(responseAl.data.data.total_albanian_companies);
  
      // Fetch today all companies
      const responseTodayAll = await axios.post("https://api.kseco.ch/company/count", { today: true });
      setCountT(parseInt(responseTodayAll.data.result[0].count));
  
      // Fetch today Albanian companies
      const responseTodayAl = await axios.get("https://api.kseco.eu/api/companies-statistics");
      setCountTAL(responseTodayAl.data.data.total_today_albanian_companies);
  
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);


  const handleServiceSearchChange = (event) => {
    const searchValue = event.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      servicesSearchTerm: searchValue,  // Track search term
      services: service.filter((s) => s.name.toLowerCase().includes(searchValue.toLowerCase())), // Filter services
    }));
  };
  
  

  // const [count, setCount] = useState([]);
  // const getCount = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   const response = await axios.post("https://api.kseco.ch/company/count", {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   setCount(response.data.result[0].count);
  // };

  // useEffect(() => {
  //   getCount();
  // }, []);

  // // const [countAl, setCountAl] = useState([]);
  // const getCountAl = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   const response = await axios.post("https://api.kseco.ch/company/count", {
  //     today: true,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   setCountAl(response.data.result[0].count);
  // };

  // useEffect(() => {
  //   getCountAl();
  // }, []);

  // const [countKS, setCountKS] = useState([]);
  // const getCountKS = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   const response = await axios.post("https://api.kseco.ch/company/count", {
  //     publication: "albanian",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   setCountKS(response.data.result[0].count);
  // };

  // useEffect(() => {
  //   getCountKS();
  // }, []);

  const filterChanged = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
    console.log(key, value);
  };


  const handleCitySearchChange = (event) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      city: event.target.value,
    })); // Set click to true to display the table or trigger re-fetch
  };
  

  const handleDateChange = (event) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: event.target.value,
    }));
  };

  return (
    <div className="">
      <Navbar />
      <div className="main">
        <div className="sidebar">
          <FilterList
            title="Publications"
            list={publicationList}
            textKey="name"
            valueKey="name"
            // value={[filter.publication]}
            value={[cleanedFilter.publication]}

            filterKey="publication"
            onChange={filterChanged}
          />
          <FilterList
            title="Canton"
            list={canton}
            textKey="name"
            valueKey="id"
            filterKey="canton"
            onChange={onCheckBoxClicked}
            value={filter.canton || []} 
          />
          <FilterList
            title="Legal Form"
            list={companyType}
            textKey="name"
            valueKey="name"
            filterKey="companyType"
            onChange={onCheckBoxClicked}
            value={filter.companyType}
          />
          {/* <FilterList
            title="Nationality"
            list={nationality}
            textKey="nationality"
            // valueKey="id"
            valueKey="nationality"
            filterKey="nationality"
            onChange={onCheckBoxClicked}
            value={filter.nationality}
          /> */}
          <NationalityFilterDashboard onFilterChange={onCheckBoxClicked} />


          <FilterList
            title="Sectors"
            list={purposes}
            textKey="name"
            valueKey="name"
            filterKey="purposes"
            onChange={onCheckBoxClicked}
            value={filter.purposes}
          />
          {/* <FilterList
            title="Services"
            list={service}
            textKey="name"
            valueKey="name"
            filterKey="services"
            className="ssss"
            onChange={onCheckBoxClicked}
            value={filter.services}
          /> */}

<div className="container tit">
  <div className={open7 ? "sidebar-item open" : "sidebar-item"}>
    <div className="sidebar-title" onClick={() => setOpen7(!open7)}>
      <span className="sp">
        <i className="bi bi-search cit"></i>
        Service Search
      </span>
      <i className="bi-chevron-down toggle-btn"></i>
    </div>
    <div className="sidebar-content services">
      <input
        type="text"
        placeholder=" &#xF52A; Search Services"
        className="service-search ch"
        value={filter.servicesSearchTerm || ""}
        onChange={handleServiceSearchChange} // Add new handler for service search
      />
    </div>
  </div>
</div>

          <FilterList
            title="Services"
            list={service} 
            textKey="name"
            valueKey="name"
            filterKey="services"
            className="ssss"
            onChange={onCheckBoxClicked}
            value={filter.services}
          />


          <div className="container tit">
            <div className={open6 ? "sidebar-item open" : "sidebar-item"}>
              <div className="sidebar-title" onClick={() => setOpen6(!open6)}>
                <span className="sp">
                  <i className="bi bi-calendar cal"></i>
                  Calendar
                </span>
                <i className="bi-chevron-down toggle-btn"></i>
              </div>
              <div className="sidebar-content">
                <input
                  type="date"
                  id="birthday"
                  placeholder="yyyy-mm-dd"
                  className="cale"
                  // onChange={(event) => {
                  //   setSearchTerm(event.target.value);
                  // }}
                  value={filter.date} // Set value from state
                  onChange={handleDateChange} // Handle date change
                />
              </div>
            </div>
          </div>
          <div className="container tit">
            <div className={open7 ? "sidebar-item open" : "sidebar-item"}>
              <div className="sidebar-title" onClick={() => setOpen7(!open7)}>
                <span className="sp">
                  <i className="bi bi-geo cit"></i>
                  City
                </span>
                <i className="bi-chevron-down toggle-btn"></i>
              </div>
              <div className="sidebar-content citys">
                <input
                  type="text"
                  placeholder=" &#xF52A; Search by City"
                  className="city ch"
                  // onChange={(event) => {
                  //   setSearchTerm(event.target.value);
                  // }}
                  value={filter.city}
                  onChange={handleCitySearchChange}
                />
              </div>
            </div>
          </div>
        </div>


        {cleanedFilter.publication ? (
          <div>
            <Publication
              filter={cleanedFilter}
              setFilter={setFilter}
              UnSelectAll={UnSelectAll}
              setSearchTerm={setSearchTerm}
              Link={Link}
              bot={bot}
              Pagination={Pagination}
              totalData={totalData}
              searchTerm={searchTerm}
              tableRef={tableRef}
              handleExcel={handleExcel}
              a={a}
              loading={loading}
              companies={companies}
              filterChanged={filterChanged}
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
            />
          </div>
        ) : (


          <div className="col-lg-12">
            <div className="crm-section">
              <button
                className="crm-card first col-lg-4"
                onClick={() =>
                  setFilter({
                    publication: "today",
                    canton: filter.canton,
                    companyType: filter.companyType,
                    page: 1,
                    size: 10,
                    purposes: filter.purposes,
                    services: filter.services,
                    nationality: filter.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1>
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countT || 0} />
                    </span>

                    <p className="pu">Today Publications</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-graph success font-large-2 float-left"></i>{" "}
                </div>
              </button>

              <button
                className="crm-card second col-lg-4 "
                onClick={() =>
                  setFilter({
                    publication: "Today Publications",
                    canton: filter.canton,
                    companyType: filter.companyType,
                    page: 1,
                    size: 10,
                    purposes: filter.purposes,
                    nationality: filter.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1 className="ha">
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countAl || 0} />
                    </span>
                    <p className="pu"> All Albanian Publication</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-bar-chart customize-icon font-large-2 float-right"></i>
                </div>
              </button>
              <button
                className="crm-card third col-lg-4 "
                onClick={() =>
                  setFilter({
                    publication: "All Publications",
                    canton: filter.canton,
                    companyType: filter.companyType,
                    page: 1,
                    size: 10,
                    purposes: filter.purposes,
                    services: filter.services,
                    nationality: filter.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1 className="ha">
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countAll || 0} />
                    </span>
                    <p className="pu"> All Publication</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-pie-chart warning font-large-2 float-right"></i>
                </div>
              </button>
            </div>
            <div className="col-lg-12">
              <div className="charts">
                <ApexChart />
                <Bar />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};          
              

  export function getFilter(filterChanged, filter, publicationList) {
    return (
      <FilterList
        title="Legal Form"
        list={publicationList}
        textKey="name"
        valueKey="name"
        filterKey="companyType"
        onChange={filterChanged}
        value={filter.companyType}
      />
    );
  }

 
  
export default Dashboard;
