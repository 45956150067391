// api.js

// Base URL of your backend API
const BASE_URL = 'https://api.kseco.eu/api'; // Update this with your backend URL

// Canton Routes
export const CANTON_API = {
  getAllCantons: `${BASE_URL}/cantons`,
  getCantonById: (id) => `${BASE_URL}/cantons/${id}`,
};

// Company Routes
export const COMPANY_API = {
  getAllCompanies: `${BASE_URL}/companies`,
  getAlbanianCompanies: `${BASE_URL}/companies/albanianCompanies`,
  getTodayPublication: `${BASE_URL}/companies/todayPublication`,
  countAllCompanies: `${BASE_URL}/companies/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/companies/countAllTodayPublications`,
  countTodayPublications: `${BASE_URL}/companies/countTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/companies/countAlbanianCompanies`,
  countMonthlyAllAndAlbanianCompanies: `${BASE_URL}/companies/countAL`,
  getCompanyById: (id) => `${BASE_URL}/companies/${id}`,


};

// Company Type Routes
export const COMPANY_TYPE_API = {
  getAllCompanyTypes: `${BASE_URL}/companiesType`,
};

// Filter Routes
export const FILTER_API = {
  filterByCanton: `${BASE_URL}/filter/canton`,
  filterByLegalForm: `${BASE_URL}/filter/legalform`,
  filterByMunicipality: (key) => `${BASE_URL}/filter/search/${key}`,
  filterByDate: (date) => `${BASE_URL}/filter/date/${date}`,
};

// Home Routes
export const HOME_API = {
  getHomeData: `${BASE_URL}/home`,
  countAllCompanies: `${BASE_URL}/home/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/home/countAllTodayPublications`,
  countAlbanianTodayPublications: `${BASE_URL}/home/countAlbanianTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/home/countAlbanianCompanies`,
  get10Companies: `${BASE_URL}/home/10Companies`,
  getMonthlyRegistration: `${BASE_URL}/home/monthlyRegistration`,
  getMonthlyCompanies: `${BASE_URL}/home/monthlyCompanies`,
  getCompanyByCanton: `${BASE_URL}/home/companyByCanton`,
};

// Nationality Routes
export const NATIONALITY_API = {
  getAllNationalities: `${BASE_URL}/nationalities`,
};


// Sector Routes
export const SECTOR_API = {
  getAllSectors: `${BASE_URL}/sectors`,
};

// Services Routes
export const SERVICES_API = {
  getAllServices: `${BASE_URL}/services`,
};
